import React, { useEffect, useRef, useState } from 'react'
import { ButtonComponent, InputComponent, ModalComponent, ReceiverRowView, SenderRowView } from '../../../commonComponents'
import { avatarPic } from '../../../images'
import { useDispatch } from 'react-redux'

import { insertChartDetails,insertFireBaseChartDetails, retrieveGroupCharList, retrieveInstructorMessages, retrireveInstructorGroupInformation } from '../../../redux/instructorLogin/action'
import { useSelector } from 'react-redux';

import './instructorChat.css';
import moment from 'moment'
const  InstructorChat=(props)=> {





    const dispatch = useDispatch();
    console.log(props?.selectedGroup,"group",props?.instructorGroupInfo)
    const [usersListModal,setUsersListModal] = useState(false);

    const [chatMessageSender,setChatMessageSender] = useState("")
    let instructorGroupsDataInformation = useSelector((state)=>state?.instructorLoginReducer?.instructorGroupInformation)

    const messagesEndRef = useRef(null);
    const [newList, setNewList] = useState();

    const [modalMembers,setModalMembers] = useState();

    useEffect(()=>{
        if (props?.instructorGroupInfo) {
          setModalMembers(props?.instructorGroupInfo)
        }
    },[props?.instructorGroupInfo])

    const handleSearch = (e)=>{
      const searchQuery = e?.target?.value.toLowerCase();
    
    if (searchQuery) {
        const filteredData = props?.instructorGroupInfo.filter(item =>
            item?.full_name.toLowerCase().includes(searchQuery));
      setModalMembers(filteredData);
  } else {
      setModalMembers(props?.instructorGroupInfo);
  }
    }

    // const [chatMessages,setChatMessages] = useState();

    let chatMessages = useSelector((state)=>state?.instructorLoginReducer?.instructorMessages)


  //   useEffect(()=>{
  //     if (chatMessages1) {
  //       console.log(chatMessages,"chatMessages")
  //       setChatMessages(chatMessages1)
  //     }
  //     else{
  //       setChatMessages([])
  //       // setNewList([])
  //     }

  // },[chatMessages1])

    const [messages,setMessages] = useState();
    const [messageList,setChatMessageList] = useState([])

        const handleModalPopup = ()=>{
        setUsersListModal(true)
    }

    const closeModal = ()=>{
        setUsersListModal(false)
    }

    const handleInputChangeFirst = (e)=>{
        setChatMessageSender(e?.target?.value)
    }



    const [newFcmToken, setNewFcmToken] = useState('');

    const handleSubmission = ()=>{
      if (chatMessageSender !== "") {

        if(window.localStorage.getItem("role_Type") == 'student'){
          const nameList = props.fullName.filter(full_name => full_name !== window.localStorage.getItem("studentFullName"));
      
          const updatedEmailList = props?.usernameList?.split(',').filter(email => email.trim() !== window.localStorage.getItem("userEmail")).join(',');
          let payload={
              "sender_name": window.localStorage.getItem("userEmail"),
              "receiver_name": updatedEmailList,
              "peer_name": props?.batchname,
              "peer_chat_type" : "group chat",
              "input": chatMessageSender,
              "msg_type": "insert",
              "peer_type": "insert",
              "receiver_user_name":nameList,
              "sender_user_name":window.localStorage.getItem("studentFullName")
          }
            dispatch(insertChartDetails(payload))
    
            let payloadFireBase={
              "sender_name":  window.localStorage.getItem("userEmail"),
              "receiver_name": props.usernameList,
              "peer_name": props?.batchname,
              "peer_chat_type" : "group chat",
              "input": chatMessageSender,
              "firebase_tokens":newFcmToken,
              "msg_type": "insert",
              "peer_type": "insert"
          }
            dispatch(insertFireBaseChartDetails(payloadFireBase))
            setChatMessageSender("")
            setNewFcmToken([])
          
        }else  if(window.localStorage.getItem("role_Type") == 'instructor'){
          const nameList = props.fullName.filter(full_name => full_name !== window.localStorage.getItem("instructorFullName"));
      
          const updatedEmailList = props?.usernameList?.split(',').filter(email => email.trim() !== window.localStorage.getItem("instructorEmail")).join(',');
          let payload={
              "sender_name": window.localStorage.getItem("instructorEmail"),
              "receiver_name": updatedEmailList,
              "peer_name": props?.batchname,
              "peer_chat_type" : "group chat",
              "input": chatMessageSender,
              "msg_type": "insert",
              "peer_type": "insert",
              "receiver_user_name":nameList,
              "sender_user_name":window.localStorage.getItem("instructorFullName")
          }
            dispatch(insertChartDetails(payload))
    
            let payloadFireBase={
              "sender_name":  window.localStorage.getItem("instructorEmail"),
              "receiver_name": props.usernameList,
              "peer_name": props?.batchname,
              "peer_chat_type" : "group chat",
              "input": chatMessageSender,
              "firebase_tokens":newFcmToken,
              "msg_type": "insert",
              "peer_type": "insert"
          }
            dispatch(insertFireBaseChartDetails(payloadFireBase))
            setChatMessageSender("")
            setNewFcmToken([])
          
        }else  if(window.localStorage.getItem("role_Type") == 'admin'){
          const nameList = props.fullName.filter(full_name => full_name !== window.localStorage.getItem("adminFullName"));
      
          const updatedEmailList = props?.usernameList?.split(',').filter(email => email.trim() !== window.localStorage.getItem("adminEmail")).join(',');
        
        
        console.log(nameList,'nameList')
        console.log(updatedEmailList,'updatedEmailList')
        console.log(props.fullName,'nameList')
        console.log(props?.usernameList,'updatedEmailList')

        
          let payload={
              "sender_name": window.localStorage.getItem("adminEmail"),
              "receiver_name": updatedEmailList,
              "peer_name": props?.batchname,
              "peer_chat_type" : "group chat",
              "input": chatMessageSender,
              "msg_type": "insert",
              "peer_type": "insert",
              "receiver_user_name":nameList,
              "sender_user_name":window.localStorage.getItem("adminFullName")
          }
            dispatch(insertChartDetails(payload))
    
            let payloadFireBase={
              "sender_name":  window.localStorage.getItem("adminEmail"),
              "receiver_name": props.usernameList,
              "peer_name": props?.batchname,
              "peer_chat_type" : "group chat",
              "input": chatMessageSender,
              "firebase_tokens":newFcmToken,
              "msg_type": "insert",
              "peer_type": "insert"
          }
            dispatch(insertFireBaseChartDetails(payloadFireBase))
            setChatMessageSender("")
            setNewFcmToken([])
          
        }

      
      
      
      }
    }

    // useEffect(() => {
    //   if (instructorGroupsDataInformation) {



    //     let payload = {
    //       "operation_type":"retrieve_fcm_token",
    //       "batch_id": props?.selectedGroup?.batch_id,
    //       "tenant_id":window.localStorage.getItem("tenant_Id"),
    //       "project_name":"lms"
    //   }
    //     dispatch(retrireveInstructorGroupInformation(payload))

    //     // const fcmToken = instructorGroupsDataInformation?.data && Object.values(instructorGroupsDataInformation?.data).map(item => item.fcmtoken);
       
    //     // // const tokenToRemove = window.localStorage.getItem("fcmToken");

    //     // // // Removing the token if it exists in the array
    //     // // const updatedTokens = fcmToken?.filter(token => token !== tokenToRemove);
    //     // setNewFcmToken(fcmToken);
    //   }
    // }, [instructorGroupsDataInformation]);

    useEffect(() => {
      if (instructorGroupsDataInformation) {
        const fcmToken = instructorGroupsDataInformation?.data && Object.values(instructorGroupsDataInformation?.data).map(item => item.fcmtoken);
       
        // const tokenToRemove = window.localStorage.getItem("fcmToken");

        // // Removing the token if it exists in the array
        // const updatedTokens = fcmToken?.filter(token => token !== tokenToRemove);
        setNewFcmToken(fcmToken);
      }
    }, [instructorGroupsDataInformation]);
    
    // Effect to handle submission when newFcmToken changes
    useEffect(() => {
      // Ensure newFcmToken has been updated
      if (newFcmToken) {
        handleSubmission();
      }
    }, [newFcmToken]);

    const handleClickFirstUser = (e)=>{
      let payload = {
        "operation_type":"retrieve_fcm_token",
        "batch_id": props?.selectedGroup?.batch_id,
        "tenant_id":window.localStorage.getItem("tenant_Id"),
        "project_name":"lms"
    }
      dispatch(retrireveInstructorGroupInformation(payload))
    

}

const[unknown,setUnkown] = useState()

    useEffect(() => {
      // Add event listener to handle messages from service worker
      const handleMessage = (event) => {
        if (event.data && event.data.type === 'background-message') {
          // Handle background message received from service worker
          const payload = event.data.payload;
          // console.log('Background message received in component:', payload);
  
          // Assuming payload structure matches your expected format
          const { body, sender_name, peer_time } = payload.data;
  
          
          if (payload?.data?.peer_name == props?.batchname) {

            console.log(payload?.data,'paload')
          setUnkown(payload?.data)
          }
        }
      };
  
      navigator.serviceWorker.addEventListener('message', handleMessage);
      
      // Clean up event listener when component unmounts
      return () => {
        navigator.serviceWorker.removeEventListener('message', handleMessage);
      };
      
    }, []);


useEffect(()=>{
  if (unknown) {

    if(window.localStorage.getItem("role_Type") == 'student'){
      let existingList = [...newList]; 

      const newDate = unknown.peer_time.split(' ')[0];
      
      const existingDateIndex = existingList.findIndex(item => item.date === moment(newDate).format('DD-MM-YYYY'));
      
      const isCurrentUserSender = unknown.sender_name === window.localStorage.getItem("userEmail");
      
      const extractedKeys = {
          message: unknown.body,
          name: unknown.sender_name,
          isSender: isCurrentUserSender,
          time: formatTime(unknown.peer_time),
          listData: false
      };
      
      const matchedStudent = props?.instructorGroupInfo?.find(student => student.username === extractedKeys.name);
      
      extractedKeys.fullName = matchedStudent
      
      console.log(extractedKeys,"exactrated")
      if (existingDateIndex !== -1) {
          existingList[existingDateIndex].chartList.push(extractedKeys);
      } else {
          existingList.push({
              date: moment(newDate).format('DD-MM-YYYY'),
              chartList: [extractedKeys]
          });
      }
      
      console.log(existingList,"he")
      setNewList(existingList);
      scrollToBottom();
    }else  if(window.localStorage.getItem("role_Type") == 'instructor'){
      let existingList = [...newList]; 

      const newDate = unknown.peer_time.split(' ')[0];
      
      const existingDateIndex = existingList.findIndex(item => item.date === moment(newDate).format('DD-MM-YYYY'));
      
      const isCurrentUserSender = unknown.sender_name === window.localStorage.getItem("instructorEmail");
      
      const extractedKeys = {
          message: unknown.body,
          name: unknown.sender_name,
          isSender: isCurrentUserSender,
          time: formatTime(unknown.peer_time),
          listData: false
      };
      
      const matchedStudent = props?.instructorGroupInfo?.find(student => student.username === extractedKeys.name);
      
      extractedKeys.fullName = matchedStudent
      
      console.log(extractedKeys,"exactrated")
      if (existingDateIndex !== -1) {
          existingList[existingDateIndex].chartList.push(extractedKeys);
      } else {
          existingList.push({
              date: moment(newDate).format('DD-MM-YYYY'),
              chartList: [extractedKeys]
          });
      }
      
      console.log(existingList,"he")
      setNewList(existingList);
      scrollToBottom();
    }else  if(window.localStorage.getItem("role_Type") == 'admin'){
      let existingList = [...newList]; 

      const newDate = unknown.peer_time.split(' ')[0];
      
      const existingDateIndex = existingList.findIndex(item => item.date === moment(newDate).format('DD-MM-YYYY'));
      
      const isCurrentUserSender = unknown.sender_name === window.localStorage.getItem("adminEmail");
      
      const extractedKeys = {
          message: unknown.body,
          name: unknown.sender_name,
          isSender: isCurrentUserSender,
          time: formatTime(unknown.peer_time),
          listData: false
      };
      
      const matchedStudent = props?.instructorGroupInfo?.find(student => student.username === extractedKeys.name);
      
      extractedKeys.fullName = matchedStudent
      
      console.log(extractedKeys,"exactrated")
      if (existingDateIndex !== -1) {
          existingList[existingDateIndex].chartList.push(extractedKeys);
      } else {
          existingList.push({
              date: moment(newDate).format('DD-MM-YYYY'),
              chartList: [extractedKeys]
          });
      }
      
      console.log(existingList,"he")
      setNewList(existingList);
      scrollToBottom();
    }
   
  

  }
},[unknown])
  


  useEffect(()=>{

    console.log(props?.result,'result')
    console.log(props?.chatData,'result')
    console.log(chatMessages?.data,'result')
    setNewList([])

    if(props?.result){
      console.log(props?.result,'SACHIN')//chatMessages?.data

      if(window.localStorage.getItem("role_Type") == 'student'){
        setChatMessageList([])
  
    
  
        const chatsMap = new Map();
    
        props?.result?.forEach(chat => {
          const date = chat.created_date.split(' ')[0];
          if (!chatsMap.has(date)) {
            chatsMap.set(date, []);
          }
      // Extract only specific keys from the chat object
    
      const isCurrentUserSender = chat.sender_name === window.localStorage.getItem("userEmail");
      console.log(props?.studentGroupInfo,"lis") 
    
          const extractedKeys = {
            message: chat.body,
            name: chat?.sender_name,
            isSender: isCurrentUserSender,
            time: formatTime(chat.created_date),
            listData: false
          
        };
        chatsMap.get(date).push(extractedKeys);
    });
    
    
    console.log(props?.instructorGroupInfo,"info")
    const newChatsArray = Array.from(chatsMap).map(([date, chartList]) => ({
      date,
      chartList: chartList.map(item => {
        const matchedStudent = props?.instructorGroupInfo?.find(student => student.username === item.name);
        // console.log("item.name:", item.name);
        // console.log("matchedStudent:", matchedStudent);
        return {
          ...item,
          fullName: matchedStudent
                };
      })
    }));
    
    
    setNewList(newChatsArray);
    
    
      }else  if(window.localStorage.getItem("role_Type") == 'instructor'){
        setChatMessageList([])
  
    
  
        const chatsMap = new Map();
    
        props?.result?.forEach(chat => {
          const date = chat.created_date.split(' ')[0];
          if (!chatsMap.has(date)) {
            chatsMap.set(date, []);
          }
      // Extract only specific keys from the chat object
    
      const isCurrentUserSender = chat.sender_name === window.localStorage.getItem("instructorEmail");
      console.log(props?.studentGroupInfo,"lis") 
    
          const extractedKeys = {
            message: chat.body,
            name: chat?.sender_name,
            isSender: isCurrentUserSender,
            time: formatTime(chat.created_date),
            listData: false
          
        };
        chatsMap.get(date).push(extractedKeys);
    });
    
    
    console.log(props?.instructorGroupInfo,"info")
    const newChatsArray = Array.from(chatsMap).map(([date, chartList]) => ({
      date,
      chartList: chartList.map(item => {
        const matchedStudent = props?.instructorGroupInfo?.find(student => student.username === item.name);
        // console.log("item.name:", item.name);
        // console.log("matchedStudent:", matchedStudent);
        return {
          ...item,
          fullName: matchedStudent
                };
      })
    }));
    
    
    setNewList(newChatsArray);
    
    
      }else  if(window.localStorage.getItem("role_Type") == 'admin'){
        setChatMessageList([])
  
    
  
        const chatsMap = new Map();
    
        props?.result?.forEach(chat => {
          const date = chat.created_date.split(' ')[0];
          if (!chatsMap.has(date)) {
            chatsMap.set(date, []);
          }
      // Extract only specific keys from the chat object
    
      const isCurrentUserSender = chat.sender_name === window.localStorage.getItem("adminEmail");
      console.log(props?.studentGroupInfo,"lis") 
    
          const extractedKeys = {
            message: chat.body,
            name: chat?.sender_name,
            isSender: isCurrentUserSender,
            time: formatTime(chat.created_date),
            listData: false
          
        };
        chatsMap.get(date).push(extractedKeys);
    });
    
    
    console.log(props?.instructorGroupInfo,"info")
    const newChatsArray = Array.from(chatsMap).map(([date, chartList]) => ({
      date,
      chartList: chartList.map(item => {
        const matchedStudent = props?.instructorGroupInfo?.find(student => student.username === item.name);
        // console.log("item.name:", item.name);
        // console.log("matchedStudent:", matchedStudent);
        return {
          ...item,
          fullName: matchedStudent
                };
      })
    }));
    
    
    setNewList(newChatsArray);
    
    
      }
    }else{

      console.log(props?.result,'SACHIN')
      // setNewList([])
    }


  

  
    // scrollToBottom();

  },[props?.result])//props?.result props?.chatData



const [chatData,setChatData] = useState();




const formatTime = (timeString) => {
    // Split the string into date and time parts
    const [datePart, timePart] = timeString.split(' ');

    // Extract hour, minute, and second from the time part
    const [hour, minute, second] = timePart.split(':').map(part => parseInt(part));

    // Format the time
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
    const formattedMinute = minute < 10 ? '0' + minute : minute;
    
    return `${formattedHour}:${formattedMinute} ${ampm}`;
  };
 

  useEffect(() => {
    scrollToBottom();
  }, [newList]); //newList



  const scrollToBottom = () => {
   
    console.log("scrollToBottom called");
    if (messagesEndRef.current) {
      console.log("messagesEndRef.current:", messagesEndRef.current);
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    };


    function isToday(dateString) {

      const today = new Date();
      const [day, month, year] = dateString.split('-').map(Number);
      const date = new Date(year, month - 1, day); // Month is zero-indexed in JavaScript Date constructor
      const currentDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    
      return date.getTime() === currentDate.getTime();
    }

    let handleCategoryModal1 = (type,id) =>{
   
    
      
    }





    const [isRecording, setIsRecording] = useState(false);
    const [audioURL, setAudioURL] = useState('');
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
  
    const handleStartRecording = () => {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
          const mediaRecorder = new MediaRecorder(stream);
          mediaRecorderRef.current = mediaRecorder;
          
          mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
              audioChunksRef.current.push(event.data);
            }
          };
  
          mediaRecorder.onstop = () => {
            const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
            const audioUrl = URL.createObjectURL(audioBlob);
            setAudioURL(audioUrl);
            audioChunksRef.current = [];
          };
  
          mediaRecorder.start();
          setIsRecording(true);
        })
        .catch(error => {
          console.error('Error accessing media devices.', error);
        });
    };
  
    const handleStopRecording = () => {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
        setIsRecording(false);
      }
    };
    
  return (
    <div className='individualChat'>
     <div className='headerProfile'>
                <div>
                    <img src={props?.selectedGroup?.course_thumbnail?props?.selectedGroup?.course_thumbnail :avatarPic}
                    style={{width:'30px', height:"25px",borderRadius:"4px"}}/>
                </div>

                <div className='headerProfileDetails'>
                    <div>
                        <b>{props?.selectedGroup?.batch_name}</b>
                    </div>
                </div>
                </div>
                <div className='listOfNames'>
                        {props?.instructorGroupInfo && props?.instructorGroupInfo.slice(0, 10).map((item,index)=>{
                            return(
                                <div>
                                    <div className='groupNames' onClick={handleModalPopup}>{item.full_name},</div>
                                </div>
                            )
                        })}
                    {props?.instructorGroupInfo && props?.instructorGroupInfo.length > 6 && <div className='groupNames'>...</div>}

                    </div>
    <div className='chatHr'>
        <hr></hr>
    </div>

    <div className='chatContent'>
   

<div>
      {newList?.map(chatGroup => (
        <div key={chatGroup.date}>
          {/* <h3>{chatGroup.date}</h3> */}

          <div className='dateContainer'>
         
          <h3 style={{  backgroundColor: '#DCF8C6', borderRadius: '10px 10px 10px 10px', padding: '7px',width: '100px', fontSize: '10px', textAlign: 'center', height: '25px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{isToday(chatGroup.date) ? "Today" : chatGroup.date}</h3>

            </div>


          <div>
         {chatGroup.chartList?.map((message, index) => {

          
           return (  <div key={index}>
               {message.isSender ? (
                <SenderRowView senderMessage={message.message} time={message.time} />
              ) : (
                <ReceiverRowView receiverMessage={message.message} time={message.time} isListData={message.isList} name={ message?.fullName?.full_name != undefined ? message?.fullName?.full_name :message?.name} checkEmail={props?.studentGroupList}/>
              )}
            </div>
            )


})}
           <div ref={messagesEndRef} />
         </div>

         



        </div>
      ))}
    </div>
    </div>

    <div className='chatHr'>
            <hr></hr>
        </div>
    <div className='sendChatContainer'>
        
            <div className='typeMessage'>
                <InputComponent  placeholder={"Type here..."} className={"typeMessage"}
                value={chatMessageSender} onChange={(e)=>handleInputChangeFirst(e)}/>
            </div>
        <div className='chatSendBtn'>
            <ButtonComponent type={"primary"}  className={"submitChat"}primary value={"Send"}
            onClick={handleClickFirstUser}/>
           
        </div>

        {/* <img src={avatarPic} className="exportIcons" onClick={() => handleCategoryModal1("Add Category", null)}  />
   
        <img src={avatarPic} className="exportIcons" onClick={() => handleCategoryModal1("Add Category", null)}  />

        <img src={avatarPic} className="exportIcons" onClick={() => handleCategoryModal1("Add Category", null)}  />

    */}
   
    </div>


    

    {usersListModal? 
            <ModalComponent title={"Group Information"}
            content={<div className='modalMembers'>
                    {/* <div className='groupLength'>{groupusers.length} <span>Members</span></div> */}
                    <div className='modalUsersSearch'>
                    <InputComponent placeholder={`${props?.instructorGroupInfo.length} members`}
                    onChange={(e)=>handleSearch(e)}/>
                    </div>
                 {modalMembers && modalMembers.map((item,index)=>{
                            return(
                                <div>
                                    <div className='groupNamesInModal'>{item.full_name}</div>
                                </div>
                            )
                        })}
            </div>}
             open={usersListModal}  closeModal={closeModal}
            />:""}
</div>  
)
}

export default InstructorChat