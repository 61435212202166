import React, { useEffect } from 'react';
import { useState } from 'react';

import { jwtDecode } from "jwt-decode";

import RegisterComponent from "../Register/register";

import { faceBookIcon,googleIcon,logo } from "../../images";

import { InputComponent,ButtonComponent,SwitchComponent ,ModalComponent} from "../../commonComponents";
import ForgotPassWordPopUp from '../forgotPassword/forgotPasswordPopUp';
import AdminRegistrationFormDetail from '../adminDemoClass/adminRegistrationFormDetails';


import { useSelector,useDispatch } from "react-redux";
import { Button, Form, Input,notification,Checkbox } from "antd";
import { useNavigate, useParams } from 'react-router-dom';

import StudentContactUsAdd from '../contactUs/studentContactUs';

import './login.css';
import { createRecordByFirebaseToken, createRecordByFirebaseTokenStudent, getLoginRole, setToken
  , studentLogin, contactUsStudent, forgotPasswordApiCall,retristrationCourseNameDetails,retrieveDate,retrieveTiming,
  getTenantIdBasedOnName } from "../../redux/studentLogin/action";
import { retriveLiveOngoingCourses } from "../../redux/studentDashboard/liveCourses/action";
import { triggerFocus } from 'antd/es/input/Input';


const CLIENT_ID = '184526489019-g3v8chejamc09fu2ctefuvv8vuheukpl.apps.googleusercontent.com';
const API_KEY = 'AIzaSyDTQNTRbeHEa-wpa3lM0OIBoX9Z9_QbvmE';
const DISCOVERY_DOC = 'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest';
const SCOPES = 'https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events';





let LoginComponent = (props) =>{
    const [form] = Form.useForm();
    const [form1] = Form.useForm();
    const [form2] = Form.useForm();
    const [form3] = Form.useForm();


    let navigate = useNavigate();

    
    
    

    let jwtConvert = jwtDecode 

    let dispatch = useDispatch();
    const params = useParams()

    const [api, contextHolder] = notification.useNotification();

  let [checked, setChecked] = useState(false);



  
 

  let loginStatus = useSelector((state)=>state.studentLoginReducer?.studentLoginData?.data);

  let check = useSelector((state)=>state.studentLoginReducer?.studentLoginData?.data);

  let loginRequests = useSelector((state)=>state?.studentLoginReducer.studentLoginData);

  let coursesRetrieve = useSelector((state)=>state?.studentLoginReducer.retrieveRegistrationCourseName);
  let dateRetrieve = useSelector((state)=>state?.studentLoginReducer.retrieveDateDetails);
  let timingRetrieve = useSelector((state)=>state?.studentLoginReducer.retrieveTimingDetails);


  let studentContactUsStatus = useSelector((state)=>state?.studentLoginReducer.studentContactUs);

  let [allRegistrationCourses, setAllRegistrationCourses] = useState(false);

  let [alldate, setAllDate] = useState();

  let [allTiming, setAllTiming] = useState();

  useEffect(() => {

    if (timingRetrieve) {
      setAllTiming(timingRetrieve?.data)
    }
}, [timingRetrieve]);

  useEffect(() => {

    if (dateRetrieve) {
      setAllDate(dateRetrieve?.data)
    }
}, [dateRetrieve]);


useEffect(() => {

  console.log(coursesRetrieve?.data,'coursesRetrieve')
  if (coursesRetrieve) {
    setAllRegistrationCourses(coursesRetrieve?.data)
  }
}, [coursesRetrieve]);


  useEffect(() => {

    console.log(studentContactUsStatus?.data?.msg,'studentContactUsStatus')
    if (studentContactUsStatus) {
       openNotification(studentContactUsStatus?.data?.msg, studentContactUsStatus?.data);

       if(studentContactUsStatus?.data?.res_status == true){
        setCancelClassModal(false)

        form.setFieldsValue({['first_name']:''})
        form.setFieldsValue({['last_name']:''})
        form.setFieldsValue({['email']:''})
        form.setFieldsValue({['phone']:''})
        form.setFieldsValue({['message']:''})
     
     
      
     
     
        setCancelClassDetails({
         "first_name":"",
         "last_name":"",
         "email":"",
         "phone":"",
         "message":"",
         "tenant_id":window.localStorage.getItem("login_tenant_id")
        })
       }else{

       }

    }
}, [studentContactUsStatus]);

let [register,setRegister] = useState(false)

  let [studentRegisterForm,setStudentRegisterForm] = useState(false);

  let [forgotPassword,setForGotpassword] = useState(false);


  let [studentLoginDetails,setStudentLoginDetails] = useState({
    email:"",
    studentPassword:""
  })

  let handleChange = (e,type) =>{
    //   setEmail(e.target.value);
    setStudentLoginDetails({...studentLoginDetails,[type.name]:e.target.value})
  }

  let studentRegister = () =>{
    setStudentRegisterForm(true)
    setRegister(true)

    console.log(register,'check')
  }

  useEffect(()=>{

    let loginPayload ={
      "operation_type": "retrieve_id",
      "tenant_name": "akalaivan" 
  }
    dispatch(getTenantIdBasedOnName(loginPayload))
  },[])

  useEffect(()=>{

    let loginPayload ={
      "operation_type": "retrieve_demo_course",
      "tenant_id": window.localStorage.getItem("login_tenant_id") 
  }
    dispatch(retristrationCourseNameDetails(loginPayload))
  },[])


  const onFinish = (values) => {



    

    if (values) {
      let loginPayload ={
        "operation_type": "stud_login",
        "op_field_details":{
          "tenant_id":window.localStorage.getItem("login_tenant_id"),
        "username": studentLoginDetails.email?.toLowerCase(),
        "password": studentLoginDetails.studentPassword
    
        }
    }
      dispatch(studentLogin(loginPayload))
    }
  };

  useEffect(() => {
    const fetchData = async () => {
        if (loginRequests) {
            try {

              console.log(loginRequests?.data?.role_type,"Role Type")
                await openNotification(loginRequests?.data?.msg, loginRequests?.data);
                window.localStorage.setItem("token", loginRequests?.data?.access_token);

                if (loginRequests?.data?.access_token?.access_token) {

                    setTimeout(()=>{
                      let decryptData = jwtConvert(loginRequests?.data?.stud_data);
                      let { student_id,email,first_name,last_name } = decryptData?.data;
                          console.log(decryptData,"userData")
                          window.localStorage.setItem("student_id", student_id)
                          window.localStorage.setItem("role_Type", loginRequests?.data?.role_type)
                          window.localStorage.setItem("tenant_Id", decryptData?.data?.tenant_id)
                          window.localStorage.setItem("userEmail",email)
                          window.localStorage.setItem("studentFullName",first_name+" "+last_name)
                        dispatch(getLoginRole(window.localStorage,loginRequests?.data?.role_type))
                      //FirebaseInsert
         
                      
                        let data ={
                          "username": window.localStorage.getItem("userEmail"),
                          "fcmtype": "web",
                          "fcmtoken": window.localStorage.getItem("fcmToken"),
                          "tenant_id": window.localStorage.getItem("tenant_Id"),
                          "project_name": "lms",
                          "full_name":window.localStorage.getItem("studentFullName")
                      }
                        dispatch(createRecordByFirebaseTokenStudent(data))
                      //end


                    },3000)

                    setTimeout(() => {
                         navigate("/studentDashboard");
                    }, 2000);
                   
                } else {
                    navigate("/");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    };

    fetchData();
}, [loginRequests]);


  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const openNotification =  (msg, status) => {
    console.log(msg, status);
        if (msg && status?.res_status == true) {
                api.info({
                    description: `${msg}`,
                });

                setForGotpassword(false)
        }
        if (msg && status?.res_status == false) {
             api.info({
                description: `${msg}`,
            });
        }


        // openNotification('','')

}


let [cancelClassModal,setCancelClassModal] = useState(false);
let [visibleRegistrationModel,setVisibleRegistrationModel] = useState(false);

let[registrationClassDetails,setRegistrationClassDetails] = useState({

  "first_name": "",
        "last_name": "",
        "email":"",
        "phone":"",
        "course_id":"",
        "timing": "",
        "date":"",
        "remark":"",
        "course_name":"",
  "tenant_id":window.localStorage.getItem("login_tenant_id")
})


let[cancelClassDetails,setCancelClassDetails] = useState({
  "first_name":"",
  "last_name":"",
  "email":"",
  "phone":"",
  "message":"",
  "tenant_id":window.localStorage.getItem("login_tenant_id")
})


let studentContactUs= () =>{
  setCancelClassModal(true)
}

let studentRegistrationClass= () =>{
  setVisibleRegistrationModel(true)
}

let onFinish1 = (values) =>{


  let cancelClassPayload ={

    
    "operation_type":"insert",
    "op_field_details":{
        "first_name":cancelClassDetails?.first_name,
        "last_name":cancelClassDetails?.last_name,
        "email":cancelClassDetails?.email,
        "phone":cancelClassDetails?.phone,
        "message":cancelClassDetails?.message,
        "tenant_id":window.localStorage.getItem("login_tenant_id"),
        "role_type":"Student"
    }
   
}


console.log(cancelClassPayload,'cancelClassPayload')


dispatch(contactUsStudent(cancelClassPayload))


}

const handleClassChange = (e,type) =>{

  if (type.name == "message") {
    setCancelClassDetails({...cancelClassDetails,[type.name]:e.target?.value})
}

if (type.name == "first_name") {
  setCancelClassDetails({...cancelClassDetails,[type.name]:e.target?.value})
}

if (type.name == "last_name") {
  setCancelClassDetails({...cancelClassDetails,[type.name]:e.target?.value})
}

if (type.name == "phone") {
  setCancelClassDetails({...cancelClassDetails,[type.name]:e.target?.value})
}

if (type.name == "email") {
  setCancelClassDetails({...cancelClassDetails,[type.name]:e.target?.value})
}

}


let handleCloseModal = () =>{

   setCancelClassModal(false)

   form.setFieldsValue({['first_name']:''})
   form.setFieldsValue({['last_name']:''})
   form.setFieldsValue({['email']:''})
   form.setFieldsValue({['phone']:''})
   form.setFieldsValue({['message']:''})


 


   setCancelClassDetails({
    "first_name":"",
    "last_name":"",
    "email":"",
    "phone":"",
    "message":"",
    "tenant_id":window.localStorage.getItem("login_tenant_id")
   })

 
}

let[forgotPasswordDetails,setForgotPasswordDetails] = useState({

  "email":""

})



let onFinish2 = (values) =>{


  let cancelClassPayload ={

    "operation_type":"student_forgot_password",
    "email":forgotPasswordDetails?.email,
    "tenant_id":window.localStorage.getItem("login_tenant_id")
   
}


console.log(cancelClassPayload,'cancelClassPayload')


dispatch(forgotPasswordApiCall(cancelClassPayload))


}

const handleClassChange2 = (e,type) =>{

  if (type.name == "email") {
    setForgotPasswordDetails({...cancelClassDetails,[type.name]:e.target?.value})
}





}

let handleCloseModal2 = () =>{

  setForGotpassword(false)


  form.setFieldsValue({['email']:''})






  setForgotPasswordDetails({
 
   "email":""

  })


}


let studentContactUs2= () =>{
  setForGotpassword(true)
}


let handleViewStudent1 = (e) =>{

  setChecked(e.target.checked);

  }




  let handleCloseModalRegistration = () =>{

    setVisibleRegistrationModel(false)
 
    form.setFieldsValue({['first_name']:''})
    form.setFieldsValue({['last_name']:''})
    form.setFieldsValue({['email']:''})
    form.setFieldsValue({['phone']:''})
    form.setFieldsValue({['message']:''})
 
 
  
 
 
    setCancelClassDetails({
     "first_name":"",
     "last_name":"",
     "email":"",
     "phone":"",
     "message":"",
     "tenant_id":window.localStorage.getItem("login_tenant_id")
    })
 
  
 }

 const handleClassChangeRegistration = (e,type) =>{

  console.log(e,'TESTINGDEMO')
  console.log(type,'TESTINGDEMO')


  if (type.name == "remark") {
    form3.setFieldsValue({[type.name]: e})

    setRegistrationClassDetails({...registrationClassDetails,[type.name]:e.target?.value})
}

if (type.name == "first_name") {
  form3.setFieldsValue({[type.name]: e})

  setRegistrationClassDetails({...registrationClassDetails,[type.name]:e.target?.value})
}

if (type.name == "last_name") {
  form3.setFieldsValue({[type.name]: e})

  setRegistrationClassDetails({...registrationClassDetails,[type.name]:e.target?.value})
}

if (type.name == "phone") {
  form3.setFieldsValue({[type.name]: e})

  setRegistrationClassDetails({...registrationClassDetails,[type.name]:e.target?.value})
}

if (type.name == "email") {
  form3.setFieldsValue({[type.name]: e})

  setRegistrationClassDetails({...registrationClassDetails,[type.name]:e.target?.value})
}
if (type.name == "course_name") {
  form3.setFieldsValue({[type.name]: e})

  setRegistrationClassDetails({...registrationClassDetails,[type.name]:e})


  let loginPayload ={

    "operation_type":"retrieve_demo_date",
    "course_id": e,
    "tenant_id": window.localStorage.getItem("login_tenant_id") 

  
}
  dispatch(retrieveDate(loginPayload))
}

if (type.name == "timing") {
  form3.setFieldsValue({[type.name]: e})

  setRegistrationClassDetails({...registrationClassDetails,[type.name]:e})



}

if (type.name == "date") {
  form3.setFieldsValue({[type.name]: e})

  setRegistrationClassDetails({...registrationClassDetails,[type.name]:e})



  let loginPayload ={

    "operation_type":"retrieve_demo_timings",
    "course_id": registrationClassDetails.course_name,
    "date":'2024-06-06',
    "tenant_id": window.localStorage.getItem("login_tenant_id") 


 

  
}
  dispatch(retrieveTiming(loginPayload))
}

}


let onFinish3 = (values) =>{


  let cancelClassPayload ={

    
    "operation_type":"insert",
    "op_field_details":{
        "first_name":registrationClassDetails?.first_name,
        "last_name":registrationClassDetails?.last_name,
        "email":registrationClassDetails?.email,
        "phone":registrationClassDetails?.phone,
        "course_id":registrationClassDetails?.course_name,
        "timing":registrationClassDetails?.timing,
        "date":registrationClassDetails?.date,
        "remark":registrationClassDetails?.remark,
        "tenant_id":window.localStorage.getItem("login_tenant_id"),
    }
   
}


console.log(cancelClassPayload,'cancelClassPayload')


// dispatch(contactUsStudent(cancelClassPayload))


}

    return (

        <>
                {contextHolder}

        {register == false ?
        <Form 
    
    autoComplete="off"
    layout="vertical"
    form={form}
      onFinish={onFinish}>
        <>
        <div className='fields'>
                    <Form.Item label="Email"
                        name="email"
                        rules={[
                            {
                            required: true,
                            message: 'Email is required',
                            type: "email",

                            },
                        ]}>
                    <InputComponent value={studentLoginDetails.email} name={"email"} className='inputField'
                     placeholder="Enter email"
                     onChange={(e)=>handleChange(e,{name: "email"})}   />
                     
                    </Form.Item>

                </div>
                <div className='fields'>
                <Form.Item
                label="Password"
                name="password"
                
             rules={[
                    {
                      required: true,
                      message: 'Password is required',
                    },
                    {
                      min: 4,
                      message: 'Password should atleast 4 characters',
                    },
                  ]}
                >
                     
                        <Input.Password value={studentLoginDetails.studentPassword} name={"studentPassword"}
                         className='' placeholder="Enter password"
                         onChange={(e)=>handleChange(e,{name: "studentPassword"})}/>
                    </Form.Item>
                    </div>

                    <div className='fields' style={{margin:"10px"}}>
                        <div className='switch'>

                        <div>
                                            <Checkbox checked={checked} onChange={(e) => handleViewStudent1(e)}>
                                             
                                            </Checkbox>

                                            <span 
                                                style={{ cursor: "pointer", color: "black", marginLeft:"10px"}}>Remember me</span>
                                        </div>

                            {/* <div><SwitchComponent /><span className='rememberMe'>Remember me</span></div> */}
                            <div className='registerLink'>
                                <p className='underLine' onClick={studentContactUs2}><b>Forgot your password?</b></p>
                            </div>
                        </div>
                    </div>

                    <div className='fields'>
                    <Form.Item>

                        <ButtonComponent type="primary"
                         className='btnLogin' value={'Login'}
                         htmlType="submit"></ButtonComponent>
                          {/* <Button type="primary" htmlType="submit">
                            Submit
                        </Button> */}
                    </Form.Item>

                    </div>
                    {/* <div className='loginWithOthers'>
                        <div className='textCenter'>
                            <p><b>Login</b> with others</p>
                        </div>
                    </div> */}

                    {/* <div className='socialIcons'>
                        <div className='facebookIcon'>
                            <img src={faceBookIcon} />
                        </div>
                        <div className='googleIcon'>
                            <img src={googleIcon} />
                        </div>
                    </div> */}

                    <div className='registerLink'>
                        <div className='textCenter'>
                           <p>Don't have account? <b className='underLine' onClick={studentRegister}>Register Now</b></p>
                        </div>
                    </div>


                    <div className='registerLink'>
                        <div className='textCenter'>
                           <p><b className='underLine' onClick={studentContactUs}>Contact Us</b></p>


                        </div>
                    </div>
{/* 
                    <div className='registerLink'>
                        <div className='textCenter'>

                           <p><b className='underLine' onClick={studentRegistrationClass}>Registration For Demo Class</b></p>

                        </div>
                    </div> */}


                    
      



                    </>

            </Form>
            : <RegisterComponent/> }


{cancelClassModal ? 
         <ModalComponent title={"Contact Us"}
        
         content={<StudentContactUsAdd 
          form2={form2} onFinish={onFinish1} 
          handleClassChange={handleClassChange}
          cancelClassDetails = {cancelClassDetails}/>}
       open={cancelClassModal} 
        handleCancel={handleCloseModal}
     //  footer= {categoryFooter}
     style={{ minHeight: `500px` }}
        ></ModalComponent>
    :""}


{visibleRegistrationModel ? 
         <ModalComponent title={"Registration For Demo Class"}
        
         content={<AdminRegistrationFormDetail 
          form3={form3} onFinish={onFinish3} 
          fieldValues = {registrationClassDetails}
          handleClassChangeRegistration={handleClassChangeRegistration}
          allRegistrationCourses={allRegistrationCourses}
          alldate={alldate}
          allTiming={allTiming}
          registrationClassDetails = {registrationClassDetails}/>}
       open={visibleRegistrationModel} 
        handleCancel={handleCloseModalRegistration}
     //  footer= {categoryFooter}
     style={{ minHeight: `500px` }}
        ></ModalComponent>
    :""}

{forgotPassword ? 
         <ModalComponent title={"Forgot Password"}
        
         content={<ForgotPassWordPopUp 
          form1={form1} onFinish2={onFinish2} 
          handleClassChange2={handleClassChange2}
          forgotPasswordDetails = {forgotPasswordDetails}/>}
       open={forgotPassword} 
        handleCancel={handleCloseModal2}
     style={{ minHeight: `500px` }}
        ></ModalComponent>
    :""}


            </>
    )
}

export default LoginComponent