// // import firebaseApp from '../src/redux/firebaseConfig/firebase-messaging-sw'

// import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import { setToken } from "./redux/studentLogin/action";

// // import * as firebase from 'firebase'
// // if ('serviceWorker' in navigator) {
// //   alert("yes")
// //   window.addEventListener('load', () => {
// //     navigator.serviceWorker.register(firebaseApp).then((registration) => {
// //       console.log('ServiceWorker registration successful with scope: ', registration.scope);
// //     }, (err) => {
// //       console.log('ServiceWorker registration failed: ', err);
// //     });
// //   });
// // }

// export const firebaseConfig=({
//   apiKey:"AIzaSyAELDRetplfLWTx0VPUIGQWm87tQaNHdlY",
//   authDomain:"e-learning-67962.firebaseapp.com",
//   projectId:"e-learning-67962",
//   storageBucket:"e-learning-67962.appspot.com",
//   messagingSenderId:"742660303601",
//   appId:"1:742660303601:web:d48476fedb12f2739b5750",
//   measurementId:"G-52QYLN2K92"
// });

// const app = initializeApp(firebaseConfig);

// // Get Firebase messaging instance
// const messaging = getMessaging(app);


// export const requestPermission = ()=>{
//   console.log("requesting permission");
//   Notification.requestPermission().then(permission=>{
//     if (permission == "granted") {
//       console.log("granted")

//       return getToken(messaging,{ vapidKey: 'BGHeP5PiqA2a_eWM_ACZnTluTr0xUFW-G2-tOuXZ7KXliqZyzMcS0sjTt0t9oxoeJnp6kjATdDv7ojb0Uf8Qgpw'})
//       .then(currentToken=>{
//         if (currentToken) {
//           console.log("client Token", currentToken)
//         }
//       })
//     }
//   })
// }

// requestPermission()



// export const onMessageListener = ()=>{
//   console.log("Listener");
//   setTimeout(()=>{
//     onMessage(messaging, (payload) => {
//       console.log("Payload", payload);
//     });
//   },3000)
  
// }
import { initializeApp } from 'firebase/app';
import { getToken, getMessaging, onMessage } from 'firebase/messaging';

const firebaseConfig=({
  apiKey:"AIzaSyAELDRetplfLWTx0VPUIGQWm87tQaNHdlY",
  authDomain:"e-learning-67962.firebaseapp.com",
  projectId:"e-learning-67962",
  storageBucket:"e-learning-67962.appspot.com",
  messagingSenderId:"742660303601",
  appId:"1:742660303601:web:d48476fedb12f2739b5750",
  measurementId:"G-52QYLN2K92"
});

console.log('*** Environment ***', "environMent")
console.log('*** Firebase Config ***', getToken)

const app = initializeApp(firebaseConfig);
const messaging =  getMessaging(app);

export const getOrRegisterServiceWorker = () => {

  if ('serviceWorker' in navigator) {
    return window.navigator.serviceWorker
      .getRegistration('/')
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register('/firebase-messaging-sw.mjs', {
          scope: '/'
        });
      });
  }
 
  throw new Error('The browser doesn`t support service worker.');
};

export const getFirebaseToken = () =>
  getOrRegisterServiceWorker()
    .then((serviceWorkerRegistration) =>
      getToken(messaging, { vapidKey: "BGHeP5PiqA2a_eWM_ACZnTluTr0xUFW-G2-tOuXZ7KXliqZyzMcS0sjTt0t9oxoeJnp6kjATdDv7ojb0Uf8Qgpw", serviceWorkerRegistration }));

export const onForegroundMessage = () =>
  new Promise((resolve) => onMessage(messaging, (payload) => resolve(payload)));


  
  // messaging.onMessage((payload) => {
  //   console.log('Message received:', payload);
  // });